@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css);
:root {
  --google-blue-color: #4285f4;
  --google-red-color: #db4437;

  --google-yellow-color: #f4b400;

  --google-green-color: #0f9d58;
}
body {
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* The following is from the book by www.kirupa.com  */

.SiteText {
  padding: 20px;
}

ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}

ul.header > li:last-child {
  float: right;
}

ul.header {
  background-color: rgb(39, 39, 39);
  padding: 0px;
}

ul.header li a {
  color: #fff;
  font-weight: normal;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.content {
  background-color: white;
  padding: 20px;
}

.content h2 {
  padding: 0;
  margin: 0;
}

.content li {
  margin-bottom: 10px;
}

/*
.active {
  background-color: #0099ff;
}
*/

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.color_red {
  color: red;
}

.color_green {
  color: green;
}

p {
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 120%;
  line-height: 160%;
}

table {
  font-family: Helvetica, Arial, sans-serif !important;
}

.hide-some {
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.hide-some:hover {
  overflow: visible;
}

.bs_regular_red_color {
  /* color: #d9534f !important; */
  color: #483d8b !important;
}

.bs_regular_blue_color {
  color: blue !important;
}

.bs_regular_orange_color {
  color: orange !important;
}

.navbar-onlinetraces {
  color: #fff !important;
}

.bs_selected {
  /* #000 is for Black*/
  color: #000 !important;
  /* #fff is for White */
  background-color: #fff !important;
}
ul li a:hover {
  /* #000 is for Black*/
  color: #000 !important;
  /* #fff is for White */
  background-color: #fff !important;
}

.isa_info,
.isa_success,
.isa_warning,
.isa_error {
  margin: 10px 0;
  padding: 12px;
}
.isa_info {
  color: #00529b;
  background-color: #bde5f8;
}
.isa_success {
  color: #4f8a10;
  background-color: #dff2bf;
}
.isa_warning {
  color: #9f6000;
  background-color: #feefb3;
}
.isa_error {
  color: #d8000c;
  background-color: #ffbaba;
}
.isa_info i,
.isa_success i,
.isa_warning i,
.isa_error i {
  margin: 10px 22px;
  font-size: 2em;
  vertical-align: middle;
}

.none-class {
  display: none !important;
}

p:hover .none-class {
  display: inline !important;
}

td:hover .none-class {
  display: inline !important;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.big150p {
  line-height: 150%;
  font-size: 150%;
}

p.big150p {
  line-height: 150%;
  font-size: 150%;
}

.background-blue1 {
  background-color: blue;
}

.background-google-green {
  background-color: #0f9d58;
  background-color: var(--google-green-color);
}

.background-light-grey {
  background-color: lightgray;
}

.background-light-green {
  background-color: lightgreen;
}

.pink-like {
  background-color: #e96baf;
}

.green-like {
  background-color: #6abf4a;
}

.red-like {
  background-color: #e2231a;
}

.color_yellow {
  color: yellow;
}
.color_blue {
  color: blue;
}
.color-white {
  color: white;
}

.color-mist {
  background-color: #90afc5;
}
.bg-color-white {
  background-color: #ffffff;
}
.bg-color-google-blue {
  background-color: #4285f4;
  background-color: var(--google-blue-color);
}
.fg-color-white {
  color: white !important;
}
.color-shadow {
  background-color: #2a3132;
}

.color-autumn-foliage {
  background-color: #763626;
}

.color_black {
  color: black;
}

.hover_effect_rgb0_123_255:hover {
  background-color: rgb(0, 123, 255);
}
.left-text-button {
  text-align: left;
}

.centered-img {
  display: block;
  margin: 0 auto;
}

.color-darkgrey {
  color: darkgrey;
}
.border-5px-solid-black {
  border: 5px solid black;
}

.padding-top-15p {
  padding-top: 15%;
}

/*For example, if we want the our 
font size in a range where 14px is the 
minimum size at the smallest viewport 
width of 300px and where 26px is 
the maximum size at the largest 
viewport width of 1600px, 
then our equation looks like this:

body {
  font-size: calc(14px + (26 - 14)
   * ((100vw - 300px) / (1600 - 300)));
}
 */

.fluid-big-title {
  font-size: calc(12px + (42 - 16) * ((100vw - 300px) / (1100 - 300)));
}

.fluid-img {
  width: calc(100px + (100 - 50) * ((100vw - 300px) / (1100 - 300)));
}

.justifiedP {
  text-align: justify;
}

.LoaderButton .spinning.glyphicon {
  margin-right: 7px;
  top: 2px;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}
@keyframes spin {
  from { -webkit-transform: scale(1) rotate(0deg); transform: scale(1) rotate(0deg); }
  to { -webkit-transform: scale(1) rotate(360deg); transform: scale(1) rotate(360deg); }
}

@media all and (min-width: 480px) {
  .Logout {
    padding: 60px 0;
  }

  .Logout form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Logout form a {
  margin-bottom: 15px;
  display: block;
  font-size: 14px;
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}

@media all and (min-width: 480px) {
  .ChangeEmail {
    padding: 60px 0;
  }

  .ChangeEmail form {
    margin: 0 auto;
    max-width: 320px;
  }
}

@media all and (min-width: 480px) {
  .ChangePassword {
    padding: 60px 0;
  }

  .ChangePassword form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.OTNavBar {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#SharingIsCaring {
  background-color: #ffffff;
}

.SharingIsCaringClass {
  background-color: lightgrey !important;
}

.width20remImportant {
  width: 10rem !important;
}

.display_inline_block {
  display: inline-block;
}

