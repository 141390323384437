.OTNavBar {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

#SharingIsCaring {
  background-color: #ffffff;
}

.SharingIsCaringClass {
  background-color: lightgrey !important;
}

.width20remImportant {
  width: 10rem !important;
}

.display_inline_block {
  display: inline-block;
}
