@media all and (min-width: 480px) {
  .Logout {
    padding: 60px 0;
  }

  .Logout form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Logout form a {
  margin-bottom: 15px;
  display: block;
  font-size: 14px;
}
